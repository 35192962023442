<template>
  <div class="commandBox">
    <div class="close-button" @click="closeCop">
      <i class="el-icon-circle-close"></i>
    </div>
    <div v-if="commanderDetailBean != null" style="width: 100%; color: aliceblue; font-size: 20px; margin-top: 3%; text-align: center">
      <div>{{ commanderDetailBean.name }} -- {{ commanderDetailBean.cnPosition }}</div>
    </div>
    <div class="body" v-if="commanderDetailBean != null">
      <div class="content">
        <div class="left-box">
          <div class="img-box">
            <img :src="commanderDetailBean.imgName" />
          </div>
        </div>
        <div class="right-box">
          <div class="text-det">
            <span class="detail">{{ commanderDetailBean.cnBiographical }}</span>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import {getCommanderInfoById} from '../utils/api/index'
export default {
  props:[
    'id'
  ],
  data() {
    return {
      commanderDetailBean: null,
      commanderList: [
        {
          src: require("../assets/img/ddg93-1.png"),
          name: "CDR Kevin M. Schaeffer",
          cnName: "",
          cnPosition: "舰长",
          position: "Commanding Officer",
          detail: `Kevin Schaeffer 指挥官出生于弗吉尼亚州亚历山大市，2004 年毕业于美国海军学院，获得计算机科学学士学位。他的第一次任期是在加利福尼亚州蒙特雷的海军研究生院，在那里他获得了系统技术硕士学位。
 
 Schaeffer 指挥官的作战任务包括在 USS 伊利湖 (CG 70) 担任辅助军官、电气官和领航员，以及在 USS Port Royal (CG 73) 担任武器官和战斗系统官的部门负责人。此外，Schaeffer 指挥官作为联合特遣部队圣骑士 J3 的一部分，支持持久自由行动，这是阿富汗的反简易爆炸装置特遣部队。
  
 在岸上，Schaeffer 司令于 2010 年至 2012 年在美国太平洋舰队的计划与政策局 (N5) 任职，并于 2015 年至 2018 年在美国印太司令部的未来作战局 (J35) 任职。Schaeffer 司令还担任执行官2018 年至 2020 年在 Afloat Training Group Middle Pacific 担任运营官和运营官。最近，他担任 USS Chung-Hoon (DDG 93) 的执行官。
 
 谢弗指挥官的军事奖项包括国防功勋奖章、功勋奖章、联合服役嘉奖奖章、海军和海军陆战队嘉奖奖章、海军和海军陆战队成就奖章，以及各种战役和单位奖项`,
        },
        {
          src: require("../assets/img/ddg93-2.png"),
          name: "CDR Peter Schunk",
          cnName: "",
          cnPosition: "副舰长",
          position: "Executive Officer",
        },
        {
          src: require("../assets/img/ddg93-3.png"),
          name: "CMDCM(SW/EXW/NAC) William P. Eickhoff",
          cnName: "",
          cnPosition: "指挥军士长",
          position: "Command Master Chief",
        },
      ],
    };
  },
  watch: {
    id(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    closeCop() {
      this.$emit("closeCop");
    },
    getDetail() {
      let params = {
        id: this.id
      };
      getCommanderInfoById(params)
        .then(res => {
          let data = res.data;
          if(data.resultCode == 0){
            if(data.resultData != null){
              this.commanderDetailBean = data.resultData
            }
          }else{

          }
        })
        .catch(err => {

        })
    }
  },
};
</script>

<style lang="less" scoped>
.commandBox {
  z-index: 9521;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 50vw;
  height: 40vh;
  background-color: rgba(0, 0, 0, 1);
  // box-shadow: -3px 0 3px -1px gray, 0 -2px 3px -1px gray, 0 2px 3px -1px gray,
  //       2px 0 3px -1px gray;
  box-shadow: 8px 8px 5px #888888;
  border-radius: 10px;
  .close-button {
    position: absolute;
    top: 1px;
    right: 2px;
    z-index: 9277;
    font-size: 30px;
    color: #fff;
  }
  .body {
    width: 100%;
    height: 100%;
    position: relative;
    .left-box {
      height: 30vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 13vw;
      text-align: center;
      color: white;
      .img-box {
        border: solid white 1.5px;
        border-radius: 3px;
        box-sizing: content-box;
        width: 80%;        
      }
      img {
        width: 100%;
      }
      .content {
        
      }
    }
    .right-box{
      position: absolute;
      right: 3%;
      top: 5%;
      bottom: 5%;
      width: 70%;
      .text-det{
        position: relative;
        width: 100%;
        height: 80%;
        overflow-y: auto;
      }
      .detail {
        white-space: pre-wrap;
        word-break: break-word;
        color:aliceblue;
        font-size: 16px;
      }
    }
  }
}
</style>
